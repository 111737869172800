<template>
  <div class="q-pa-lg">
    <h5 class="text-h5 text-weight-medium q-mt-none q-mb-lg">Top up balance</h5>

    <q-card :style="{ maxWidth: '400px' }" flat>
      <q-card-section>
        <q-linear-progress
          v-if="loading"
          :style="{ top: '0', left: '0px' }"
          indeterminate
          size="3px"
          color="primary"
          class="absolute"
        />
        <p class="flex justify-between items-center text-subtitle1 q-mb-none">
          <b :style="{ minWidth: '100px' }" class="q-mr-sm">Balance:</b>
          {{
            currency
              ? formatMoney({
                  value: balance,
                  currency,
                  showSymbol: false,
                })
              : 0
          }}
        </p>

        <p
          class="flex justify-between items-center text-subtitle1 q-mt-md q-mb-none"
        >
          <b :style="{ minWidth: '100px' }" class="q-mr-sm">Currency:</b>
          {{ currency }}
        </p>
      </q-card-section>
    </q-card>

    <div
      :style="{ minHeight: '245px' }"
      class="relative-position overflow-hidden q-mt-lg"
    >
      <nav class="row q-col-gutter-md">
        <div
          v-for="(method, methodIndex) in methods"
          :key="methodIndex"
          class="col-12 col-sm-6 col-md-4 col-lg-3"
        >
          <q-btn
            unelevated
            color="white"
            text-color="black"
            class="full-width"
            @click="
              togglePaymentDialog({
                showed: true,
                methodData: method,
              })
            "
          >
            <div class="full-width">
              <q-img
                :src="method.logo"
                :ratio="1.5"
                :style="{ maxWidth: '200px' }"
                contain
                width="calc(100% - 60px)"
                class="q-my-md q-mx-auto block"
              />

              <h3 class="text-subtitle1 text-weight-medium q-mt-none q-mb-md">
                {{ method.title }}
              </h3>
            </div>
          </q-btn>
        </div>
      </nav>

      <q-inner-loading :size="60" :showing="loading" color="primary" />
    </div>

    <q-dialog
      v-if="paymentDialog.showed"
      v-model="paymentDialog.showed"
      :persistent="paymentDialog.loading || !!paymentDialog.topUpResult"
      transition-show="jump-up"
    >
      <q-card :style="{ maxWidth: '400px' }" class="full-width">
        <q-card-section>
          <q-btn
            :style="{ top: '5px', right: '5px' }"
            flat
            round
            size="sm"
            color="primary"
            icon="mdi-close"
            class="q-ml-sm absolute"
            @click="
              togglePaymentDialog({
                showed: false,
              })
            "
          />

          <payment-form
            v-if="paymentDialog.methodData"
            :data="paymentDialog.methodData"
            :loading="paymentDialog.loading"
            @submit="onSubmitPaymentForm"
          />

          <top-up-result
            v-else-if="paymentDialog.topUpResult"
            :data="paymentDialog.topUpResult"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { balanceController } from '@/shared/api';
import {
  authUtils,
  cloneStructured,
  formatMoney,
  notify,
} from '@/shared/utils';

export default {
  name: 'TopUpBalance',
  data() {
    return {
      loading: false,
      currency: null,
      balance: 0,
      methods: [],
      paymentDialog: {
        loading: false,
        showed: false,
        methodData: null,
        topUpResult: null,
      },
    };
  },
  components: {
    PaymentForm: () => import('./components/payment-form'),
    TopUpResult: () => import('./components/top-up-result'),
  },
  methods: {
    formatMoney() {
      return formatMoney(...arguments);
    },
    async getBalaceTopUpMethods() {
      if (this.loading) return;

      this.loading = true;

      try {
        const { balance, currency, methods } = await balanceController(
          authUtils.getRoleByHierarchy()
        ).getBalaceTopUpMethods();

        this.methods = Object.freeze(
          methods.map((method) => ({
            ...method,
            logo: method.logo || require('./img/common-payment-method.png'),
          }))
        );
        this.balance = balance;
        this.currency = currency;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    togglePaymentDialog({ showed, methodData = null, topUpResult = null }) {
      this.paymentDialog.showed = showed;
      this.paymentDialog.methodData = Object.freeze(
        cloneStructured(methodData)
      );
      this.paymentDialog.topUpResult = Object.freeze(
        cloneStructured(topUpResult)
      );
    },
    async onSubmitPaymentForm({ identity, fields }) {
      this.paymentDialog.loading = true;

      try {
        const payload = fields.map(({ name: key, value }) => ({
          key,
          value,
        }));

        const { payload: topUpResult } = await balanceController(
          authUtils.getRoleByHierarchy()
        ).balanceTopUp({
          identity,
          payload,
        });

        this.paymentDialog.loading = false;

        this.togglePaymentDialog({
          showed: true,
          topUpResult,
        });

      } catch {
        this.paymentDialog.loading = false;
        notify.error('Error 400. Something went wrong!');
      }
    },
  },
  mounted() {
    this.getBalaceTopUpMethods();
  },
};
</script>
